import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Scrollspy from 'vue2-scrollspy';
import firebase from "firebase";
import 'firebase/auth'
//import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import StatusIndicator from 'vue-status-indicator';
import Vue2Filters from 'vue2-filters'
import "@/assets/css/materialdesignicons.min.css";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(Vue2Filters);
Vue.use(StatusIndicator);
Vue.use(VueTelInput);
Vue.use(VueAxios, axios);

const firebaseConfig = {
  apiKey: "AIzaSyCGCO8PGofeiLUgZ3WTzXd5Jm6ew-XxgUk",
  authDomain: "leapfrogr.firebaseapp.com",
  databaseURL: "https://leapfrogr-default-rtdb.firebaseio.com",
  projectId: "leapfrogr",
  storageBucket: "leapfrogr.appspot.com",
  messagingSenderId: "20695633827",
  appId: "1:20695633827:web:92d62f008cefb257ad1416",
  measurementId: "G-1ML5DXJKS4"
};

//Your Firebase Config Api key Here
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



var VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false

import "@/assets/scss/style.scss";


Vue.use(Scrollspy);
Vue.use(VueScrollTo)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
